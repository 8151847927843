.welcome-message{
    color: #BD9090;
    text-decoration: none;
    justify-self: start;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 35px;
    width: 50%;
    text-align: center;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}